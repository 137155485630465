import Vue from "vue";
import Vuex from "vuex";
import auth from "@/services/auth";
import account from "@/services/account";
import hasFlag from "@/utils/hasFlag";
Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    token: null,
    session: null,
    user: null,
    permissionsLastUpdate: null,
    permissions: [],
    permissionsGroups: {},
    cacheUser: false,
    config: {
      opMode: false,
      sudoMode: false,
    },
  },
  getters: {
    session: (state) => state.session,
    isAuthenticated: (state) => state.token,
    user: (state) => state.user,
    userFirstName: (state) => state.user.name.split(" ")[0],
    userPermissions: (state) => state.user?.OrganizationMember || [],
    permissions: (state) => state.permissions,
    permissionsGroups: (state) => state.permissionsGroups,
    cacheUser: (state) => state.cacheUser,
    hasPermission: (state, getters, rootState) => (permission, partyId) => {
      const selectedOrganization = rootState.organization.selectedOrganization;
      const orgPermissions = getters.userPermissions.find(
        (up) => up.organizationId === selectedOrganization
      );
      if (!orgPermissions) return false;

      // Party Co-Organizer permissions
      if (Object.keys(orgPermissions.parties || {}).length > 0) {
        const partyRouter = partyId || getPartyRouter();
        if (partyRouter) {
          const partyPermissions = orgPermissions.parties[partyRouter];
          if (partyPermissions) {
            if (Array.isArray(permission)) {
              return permission.some((p) => verifyPermission(p, partyPermissions));
            } else {
              return verifyPermission(permission, partyPermissions);
            }
          }
        }
      }

      if (orgPermissions.owner) return true;

      if (Array.isArray(permission)) {
        return permission.some((p) => verifyPermission(p, orgPermissions));
      } else {
        return verifyPermission(permission, orgPermissions);
      }
    },
    flags: (state) => state.user?.Flags || [],
    hasFlag:
      (state, getters) =>
        (flag, value = true) =>
          hasFlag.hasFlag(getters.flags, flag, value),
    flagValue: (state, getters) => (flag) =>
      hasFlag.flagValue(getters.flags, flag),
    config: (state) => state.config,
  },
  mutations: {
    setLogin(state, { token, session, user, authenticators }) {
      state.token = token;
      state.session = Object.assign(state.session || {}, session);
      state.user = user;
      if (!!user)
        state.cacheUser = {
          id: user.id,
          name: user.name,
          phone: user.phone,
          photo: user.photo,
          ddi: user.ddi,
          authenticators: authenticators || state.cacheUser.authenticators,
        };
    },
    setPermissions(state, { permissions, permissionsGroups }) {
      state.permissions = permissions;
      state.permissionsGroups = permissionsGroups;
      state.permissionsLastUpdate = new Date();
    },
    clearCacheUser(state) {
      state.cacheUser = false;
    },
    setUserPhoto(state, photo) {
      state.user.photo = photo;
    },
    setConfig(state, config) {
      state.config = {
        ...state.config,
        ...config,
      };
    },
  },
  actions: {
    setUserPhoto({ commit }, photo) {
      commit("setUserPhoto", photo);
    },
    async setLogin({ commit }, { token, session, user, authenticators = [] }) {
      commit("setLogin", { token, session, user, authenticators });
    },
    async login({ commit }, data) {
      let response = await auth.login(data);
      commit("setLogin", response);
      return response;
    },
    async renewToken({ commit, state, dispatch }) {
      try {
        let response = await auth.renewToken(state.session.token);
        commit("setLogin", response);
        return response;
      } catch (e) {
        if (!navigator.onLine || e.message === "Network Error") return true;

        console.log("Renew Token error:", e);
        await dispatch("logout");
        return false;
      }
    },
    async getPermissions({ commit, state }) {
      if (
        state.permissionsLastUpdate &&
        new Date(state.permissionsLastUpdate) > new Date() - 1000 * 60 * 60 * 12
      )
        return {
          permissions: state.permissions,
          permissionsGroups: state.permissionsGroups,
        };
      let response = await auth.permissions();
      commit("setPermissions", response);
      return response;
    },
    async logout({ commit, state, dispatch }) {
      await account.session.remove(state.session.id).catch(() => { });

      dispatch("app/logout", null, { root: true });
      commit("setLogin", { token: null, session: null, user: null });
    },
    clearCacheUser({ commit }) {
      commit("clearCacheUser");
    },
    toggleOpMode({ commit, state }) {
      commit("setConfig", { opMode: !state.config.opMode });
      return !state.opMode;
    },
  },
};

const verifyPermission = (permission, orgPermissions) =>
  (orgPermissions.permission & permission) === permission;

const getPartyRouter = () => {
  const url = window.location.href;
  //regex for parties/uuid
  const regex = /parties\/([a-zA-Z0-9-]+)/;
  const found = url.match(regex);
  return found ? found[1] : null;
}