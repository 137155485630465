/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

Vue.use(Router);

import appRoutes from "./modules/app";
import shopRoutes from "./modules/shop";
import staffRoutes from "./modules/staff";
import adminRoutes from "./modules/admin";
import authRoutes from "./modules/auth";
import proxyRoutes from "./modules/proxy";
import verifyRoutes from "./modules/verify";

import { active } from "@/themeConfig.js";

const mainteneceRoutes = [
  {
    path: "/",
    meta: { layout: "auth" },
    component: () => import("@/views/global/Clear"),
    children: [
      {
        path: "",
        name: "maintenance",
        component: () => import("@/views/app/sessions/Maintenence"),
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;
    return { x: 0, y: 0 };
  },
  routes: !active
    ? mainteneceRoutes
    : [
        // App
        ...appRoutes,
        // Shop
        ...shopRoutes,
        // staff
        ...staffRoutes,
        // Admin
        ...adminRoutes,
        // Auth
        ...authRoutes,
        // Proxy
        ...proxyRoutes,
        // Verify
        ...verifyRoutes,

        // Global
        {
          path: "/",
          name: "home",
          component: () => import("@/views/global/Index"),
          children: [
            {
              path: "",
              name: "home.index",
              meta: { layout: "HorizontalBar", hiddenAppBar: true },
              component: () => import("@/views/home/Index"),
            },
            {
              path: "/terms",
              name: "terms.index",
              meta: { layout: "HorizontalBar", hiddenAppBar: true },
              component: () => import("@/views/app/terms/Index"),
            },
            {
              path: "/brand",
              name: "brand.index",
              meta: { layout: "HorizontalBar", hiddenAppBar: true },
              component: () => import("@/views/app/Brand"),
            },
            {
              path: "/terms/contract",
              name: "terms.contract",
              meta: { layout: "HorizontalBar", hiddenAppBar: true },
              component: () => import("@/views/app/terms/Contract"),
            },
            {
              path: "/terms/privacy",
              name: "terms.privacy",
              meta: { layout: "HorizontalBar", hiddenAppBar: true },
              component: () => import("@/views/app/terms/Privacy"),
            },
          ],
        },
        {
          path: "*",
          redirect: "/",
        },
      ],
});

router.beforeEach((to, from, next) => {
  if (to.path !== from.path) {
    store.dispatch("changeThemeLoadingState", true);
  }
  next();
});

router.afterEach(() => {
  setTimeout(() => store.dispatch("changeThemeLoadingState", false), 0);
});

export default router;
