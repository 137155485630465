import http from "@/http-common";

// Party
const getAllParies = async (orgId) => {
  return await http
    .get(`/admin/organization/${orgId}/party`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const getOneParty = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const createParty = async (orgId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/party`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const updateParty = async (data) => {
  const { organizationId, id } = { ...data };
  delete data.organizationId;
  delete data.id;

  return await http
    .put(`/admin/organization/${organizationId}/party/${id}`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const uploadDocument = (orgId, partyId, type, file) => {
  if (!file) throw new Error("file is required");

  var formData = new FormData();
  formData.append("file", file);

  return http
    .post(
      `/admin/organization/${orgId}/party/${partyId}/document/${type}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((r) => r.data)
    .catch((e) => {
      throw e.response.data || e;
    });
};

const uploadPartyCover = (orgId, partyId, file) => {
  if (!file) throw new Error("file is required");

  var formData = new FormData();
  formData.append("file", file);

  return http
    .post(`/admin/organization/${orgId}/party/${partyId}/cover`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((r) => r.data)
    .catch((e) => {
      throw e.response.data || e;
    });
};
const uploadPartyMap = (orgId, partyId, file) => {
  if (!file) throw new Error("file is required");

  var formData = new FormData();
  formData.append("file", file);

  return http
    .post(`/admin/organization/${orgId}/party/${partyId}/staticMap`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((r) => r.data)
    .catch((e) => {
      throw e.response.data || e;
    });
};

const getRefunds = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/refound`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const getRefundRequest = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/refound/request`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const closeRefoundRequest = async (orgId, partyId, refoundId, body) => {
  return await http
    .put(
      `/admin/organization/${orgId}/party/${partyId}/refound/request/${refoundId}`, body
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

// Reports
const prPayments = async (orgId, partyId, month) => {
  return await http
    .get(
      `/admin/organization/${orgId}/party/${partyId}/reports/payments?month=${month}`
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const prTickets = async (orgId, partyId, month) => {
  return await http
    .get(
      `/admin/organization/${orgId}/party/${partyId}/reports/tickets?month=${month}`
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const prResume = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/reports/resume`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const prSaleLink = async (orgId, partyId, saleLinkId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/reports/saleLink/${saleLinkId}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const prCourtesy = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/reports/courtesy`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const prGuests = async (orgId, partyId) => {
  return await http

    .get(`/admin/organization/${orgId}/party/${partyId}/reports/guests`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const prGuestDetail = async (orgId, partyId, guestId) => {
  return await http

    .get(
      `/admin/organization/${orgId}/party/${partyId}/reports/guest/${guestId}`
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const prPaymentDetail = async (orgId, partyId, paymentId) => {
  return await http
    .get(
      `/admin/organization/${orgId}/party/${partyId}/reports/payment/${paymentId}`
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const ticketDetails = async (orgId, partyId, ticketId) => {
  return await http
    .get(
      `/admin/organization/${orgId}/party/${partyId}/reports/ticket/${ticketId}`
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const offlineRecallGetAll = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/offline-recall`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const offlineRecallCreate = async (orgId, partyId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/party/${partyId}/offline-recall`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const saleLinkGetAll = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/sale-link`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const saleLinkCreate = async (orgId, partyId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/party/${partyId}/sale-link`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const saleLinkUpdate = async (orgId, partyId, data) => {
  const { id } = { ...data };
  delete data.id;
  return await http
    .put(`/admin/organization/${orgId}/party/${partyId}/sale-link/${id}`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

// Attractions
const attractionGetAll = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/attraction`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const attractionCreate = async (orgId, partyId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/party/${partyId}/attraction`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const attractionUpdate = async (orgId, partyId, attractionId, data) => {
  return await http
    .put(
      `/admin/organization/${orgId}/party/${partyId}/attraction/${attractionId}`,
      data
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const attractionImage = async (orgId, partyId, attractionId, file) => {
  if (!file) throw new Error("file is required");

  var formData = new FormData();
  formData.append("file", file);

  return http
    .put(
      `/admin/organization/${orgId}/party/${partyId}/attraction/${attractionId}/image`,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    )
    .then((r) => r.data)
    .catch((e) => {
      throw e.response.data || e;
    });
};

// Rating
const ratingGetAll = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/rating`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

// Entrance
const entrancePartySessionGetAll = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/entrance/session`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const entrancePartySessionCreate = async (orgId, partyId, data) => {
  return await http
    .post(
      `/admin/organization/${orgId}/party/${partyId}/entrance/session`,
      data
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const entrancePartySessionUpdate = async (orgId, partyId, id, data) => {
  return await http
    .put(
      `/admin/organization/${orgId}/party/${partyId}/entrance/session/${id}`,
      data
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const entrancePartySessionReport = async (orgId, partyId, id, data) => {
  return await http
    .get(
      `/admin/organization/${orgId}/party/${partyId}/entrance/session/${id}/report`,
      data
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const entrancePartyTicketGetAll = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/entrance/ticket`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const entranceFindByCPF = async (orgId, partyId, cpf) => {
  return await http
    .get(
      `/admin/organization/${orgId}/party/${partyId}/entrance/ticket/cpf/${cpf}`
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const entrancePartyTicketGetDetails = async (orgId, partyId, code) => {
  return await http
    .get(
      `/admin/organization/${orgId}/party/${partyId}/entrance/ticket/${code}`
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const entrancePartyTicketRegister = async (orgId, partyId, code, approved) => {
  return await http
    .post(
      `/admin/organization/${orgId}/party/${partyId}/entrance/ticket/${code}`,
      { approved }
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getAnalytics = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/reports/analytics`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

import gallery from "./gallery.js";
import map from "./map.js";
import whatsappGroup from "./whatsappGroup.js";
import period from "./period.js";
import seller from "./seller.js";
import coOrganizer from "./coOrganizer.js";
export default {
  getAll: getAllParies,
  getOne: getOneParty,
  create: createParty,
  update: updateParty,
  document: uploadDocument,
  cover: uploadPartyCover,
  staticMap: uploadPartyMap,
  refund: {
    getAll: getRefunds,
    getAllRequests: getRefundRequest,
    closeRefoundRequest: closeRefoundRequest,
  },
  offlineRecall: {
    getAll: offlineRecallGetAll,
    create: offlineRecallCreate,
  },
  saleLink: {
    getAll: saleLinkGetAll,
    create: saleLinkCreate,
    update: saleLinkUpdate,
  },
  attraction: {
    getAll: attractionGetAll,
    create: attractionCreate,
    update: attractionUpdate,
    image: attractionImage,
  },
  rating: {
    getAll: ratingGetAll,
  },
  entrance: {
    session: {
      getAll: entrancePartySessionGetAll,
      create: entrancePartySessionCreate,
      update: entrancePartySessionUpdate,
      report: entrancePartySessionReport,
    },
    ticket: {
      findByCpf: entranceFindByCPF,
      getAll: entrancePartyTicketGetAll,
      getDetails: entrancePartyTicketGetDetails,
      register: entrancePartyTicketRegister,
    },
  },
  reports: {
    payments: prPayments,
    tickets: prTickets,
    guests: prGuests,
    resume: prResume,
    saleLink: prSaleLink,
    guestDetail: prGuestDetail,
    analytics: getAnalytics,
    paymentDetail: prPaymentDetail,
    ticketDetails: ticketDetails,
    courtesy: prCourtesy,
  },
  gallery,
  map,
  whatsappGroup,
  period,
  seller,
  coOrganizer,
};
